import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import './App.css'; // Import your CSS file for the modal styles
import { AssetResponse, TalentCard, Asset } from './types';
import React, { useEffect, useState, Suspense, lazy, memo } from 'react';
import axios from 'axios';

const LastNewsDisplayLazy = memo(lazy(() => import('./lastNewsDisplay')));
const LastSalesLazy = lazy(() => import('./lastSales'));
const TalentDescriptionLazy = lazy(() => import('./TalentDescription'));
const ModalTalentScoreLazy = lazy(() => import('./modalTalentScore'));
const TalentPerformanceChartLazy = lazy(() => import('./chartTalentPerformance'));
const ChartComponentLazy = lazy(() => import('./chartTalentValue'));
const NewsStatusLazy = lazy(() => import('./lastNewsDisplay'));


const isMobile = window.innerWidth <= 768;

interface PageViewModalProps {
  isVisible: boolean;
  onClose: () => void;
  asset: Asset | null;
  dispCurrency: string | null;
  handleProfileButtonClick: (talentName: string, toDisplay: string) => void;
}

const PageViewModal: React.FC<PageViewModalProps> = ({
  isVisible,
  onClose,
  asset,
  dispCurrency,
  handleProfileButtonClick,
}) => {
  const [displayContent, setDisplayContent] = useState<string>('');
  const [followersTotal, setFollowersTotal] = useState<number | null>(null);
  const [isModalTalentScoreOpen, setIsModalTalentScoreOpen] = useState(false); // Modal state
  const [selectedBar, setSelectedBar] = useState<{ x: string; y: number } | null>(null); // Bar data state
  const [description, setDescription] = useState('');
  const pxlLanguage = localStorage.getItem('pxlLanguage') || 'en';
  const [loading, setLoading] = useState(false);

  const handleFollowersUpdate = (followers: number) => {
    setFollowersTotal(followers);
  };

  const { t } = useTranslation();
  const [newsNumber, setNewsNumber] = useState(0);
  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';


    const handleNewsCount = (hasNews:any) => {
      setNewsNumber(hasNews); // Update the state based on whether news exists
    };

    useEffect(() => {
      if (asset?.talentCard.talent_description) {
        // Clear the description to avoid showing the previous one while fetching the new one
        setDescription(t('loading'));
    
        if (pxlLanguage === 'en') {
          setDescription(asset.talentCard.talent_description);
        } else {
          fetchTranslation(asset.talentCard.talent_name_original);
        }
      } else {
        // If no asset or description is available, clear the description
        setDescription('');
      }
    }, [asset, pxlLanguage]);


  useEffect(() => {
    if (asset) {
      let content = '';

      if (dispCurrency === 'USD') {
        const value = parseFloat(asset.talentCard.USD_value).toFixed(2);
        const ratio = parseFloat(asset.talentCard.USD_ratio).toFixed(2);
        const ratioClass = parseFloat(asset.talentCard.USD_ratio) > 0 ? 'green-text' : 'red-text';

        content = `<div style="color: #FAFAFA; width: 100%; font-weight: 400; font-size: 120%; padding-top: 20px; padding-bottom: 20px;">
          $<b>${value}</b>&nbsp;&nbsp;<span class="${ratioClass}" style="margin-right:15px;">${ratio}%</span>
        </div>`;
      } else if (dispCurrency === 'EUR') {
        const value = parseFloat(asset.talentCard.EUR_value).toFixed(2);
        const ratio = parseFloat(asset.talentCard.EUR_ratio).toFixed(2);
        const ratioClass = parseFloat(asset.talentCard.EUR_ratio) > 0 ? 'green-text' : 'red-text';

        content = `<div style="color: #FAFAFA; width: 100%; font-weight: 400; font-size: 120%; padding-top: 20px; padding-bottom: 20px;">
          <b>${value}</b>€&nbsp;&nbsp;<span class="${ratioClass}" style="margin-right:15px;">${ratio}%</span>
        </div>`;
      }

      setDisplayContent(content);
    }
  }, [asset, dispCurrency]);

  




  const fetchTranslation = async (talentName: string) => {
    setLoading(true);
    try {
      const response = await axios.get(`${linkUrl}/wp-json/custom/v1/get-translation/`, {
        params: {
          talent_name: talentName,
          language: pxlLanguage,
        },
      });
  
      if (response.data && response.data.description) {
        // Use the existing translation
        setDescription(response.data.description);
        console.log('response.data.descriptionBIBI',response.data.description);
      } else {
        // No translation found, use OpenAI API to translate
        const translatedDescription = await fetchOpenAITranslation(asset?.talentCard.talent_description || '');
        setDescription(translatedDescription);
        // Save the new translation for future users
        await saveTranslationToWordPress(talentName, translatedDescription);
      }
    } catch (error) {
      console.error('Error fetching translation:', error);
    } finally {
      setLoading(false);
    }
  };



  const fetchOpenAITranslation = async (text: string) => {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;  // Use your API key
    const openaiUrl = 'https://api.openai.com/v1/chat/completions';  // Use the chat completion endpoint
  
    // Map language codes to actual language names
    const languageMapping = {
      fr: 'French',
      sp: 'Spanish',
      it: 'Italian',
      de: 'German',
      pt: 'Portuguese',
      nl: 'Dutch',
      kr: 'Korean',
      jp: 'Japanese',
      cn: 'Chinese',
      ru: 'Russian',
      ar: 'Arabic',
      he: 'Hebrew',
      en: 'English'  // Fallback to English if pxlLanguage is not found
    };
  
    const targetLanguage = languageMapping[pxlLanguage as keyof typeof languageMapping] || 'English';

    console.log('targetLanguage',targetLanguage);
  
    try {
      const response = await axios.post(
        openaiUrl,
        {
          model: 'gpt-3.5-turbo',  // Use GPT-3.5-turbo or GPT-4
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            {
              role: "user",
              content: `Please translate the following text to ${targetLanguage}: "${text}"`
            }
          ]
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log('response.dataAI',response.data);
      // Process the response and return the translated text
      if (response.data && response.data.choices && response.data.choices.length > 0) {
        return response.data.choices[0].message.content.trim();  // Extract the translated text
      }
  
    } catch (error) {
      console.error('Error with OpenAI API:', error);
    }
  
    return text;  // Fallback to the original text if translation fails
  };
  






  // Save translated description to WordPress for future use
  const saveTranslationToWordPress = async (talentName: string, translatedText: string) => {
    try {
      await axios.post(`${linkUrl}/wp-json/custom/v1/save-translation/`, {
        talent_name: talentName,
        language: pxlLanguage,
        translated_text: translatedText,
      });
    } catch (error) {
      console.error('Error saving translation:', error);
    }
  };





  const handleBarClick = (barData: { x: string; y: number }) => {
    console.log('barData',barData);
    setSelectedBar(barData);
    setIsModalTalentScoreOpen(true); // Open modal with bar data
  };

  const handleCloseModal = () => {
    setIsModalTalentScoreOpen(false); // Close the modal
  };

  const handleFirstButtonClick = () => {
    if (asset?.name) {
      handleProfileButtonClick(asset?.name, 'buyoffers');
    }
  };

  const handleSecondButtonClick = () => {
    if (asset?.name) {
      handleProfileButtonClick(asset?.name, 'selloffers');
    }
  };

 

  if (!isVisible) {
    return null;
  }
  const main_activity_for_t = asset?.talentCard.main_activity.toString() || '';
  const main_activity_html = asset?.talentCard.main_activity
  ? `<span class="tagTalentClass">
        <b>${t(main_activity_for_t)}</b>
      </span>`
    : '';
  
  const main_category_for_t = asset?.talentCard.main_category.toString() || '';
  const main_category_html = asset?.talentCard.main_category
    ? `<span class="tagTalentClass">
          <b>${t(main_category_for_t)}</b>
        </span>`
      : '';
 
const national_team_for_t = asset?.talentCard.national_team.toString() || '';
  const nationClub_html = asset?.talentCard.national_team
    ? `<span class="tagTalentClass">
        ${asset.talentCard.national_team_url
          ? `<img src="${asset.talentCard.national_team_url}" alt="National Team" style="max-height: 12px; border-radius:2px;">&nbsp;`
          : ''}
        <b>${t(national_team_for_t)}</b>
      </span>`
    : '';

  const leagueClub_for_t = asset?.talentCard.league.toString() || '';
  const leagueClub_html = asset?.talentCard.league
    ? `<span class="tagTalentClass">
        ${asset.talentCard.league_url
          ? `<img src="${asset.talentCard.league_url}" alt="League" style="max-height: 12px; border-radius:2px;">&nbsp;`
          : ''}
        <b>${t(leagueClub_for_t)}</b>
      </span>`
    : '';

  const nameClub_for_t = asset?.talentCard.club.toString() || '';
  const nameClub_html = asset?.talentCard.club
    ? `<span class="tagTalentClass">
        ${asset.talentCard.club_url
          ? `<img src="${asset.talentCard.club_url}" alt="Club" style="max-height: 12px; border-radius:2px;">&nbsp;`
          : ''}
        <b>${t(nameClub_for_t)}</b>
      </span>`
    : '';

    
  const tags_html = [0, 1, 2, 3, 4, 5]
    .map((i) => {
      const tag = asset?.talentCard[`tag${i}` as keyof TalentCard];
      const tag_for_t = tag?.toString() || '';
      return tag ? `<span class="prizeTalentClass2">${t(tag_for_t)}</span>` : '';
    })
    .join('');

  const prizes_html = [1, 2, 3, 4, 5]
    .map((i) => {
      const prize = asset?.talentCard[`prize${i}` as keyof TalentCard];
      const prize_for_t = prize?.toString() || '';
      return prize ? `<span class="prizeTalentClass2">${t(prize_for_t)}</span>` : '';
    })
    .join('');

    const formatDescription = (description: string) => {
      return description
        .split('\n\n')
        .map(paragraph => `<p>${paragraph.replace(/\n/g, '<br>')}</p>`)
        .join('');
    };

    const formattedDescription = description
  ? formatDescription(description)
  : '';


  const mainNationality = asset?.talentCard.main_nationality.toString() || '';

  return (
    <div className="talent-modal">

{(isModalTalentScoreOpen===false) && (
      <div className="stickyMenuPVM">
        <div onClick={onClose} className="closeButtonPageViewPVM">
          <i className="fa-solid fa-chevron-left"></i> {t('back')}
        </div>
        <div className="profile-info-buttons">
          <div
            id="talentAverageValue"
            dangerouslySetInnerHTML={{ __html: displayContent }}
          ></div>
          <div className="sell-button" onClick={handleSecondButtonClick}>
            {t('myCards')} <span id="seeMyCards"></span>
          </div>
          <div className="buy-button" onClick={handleFirstButtonClick}>
            {t('buyCard')} <span id="buyMyCards"></span>
          </div>
        </div>
      </div>
)}

      <div className="modal-content-PVM">
        <div className="product-details">
          {!isMobile &&(
          <div className="product-image-columnPVM">
            {/* Display a picture here */}
            <img src={asset?.talentCard.profile_new_url} alt="Profile" className="profilePicturePVM" />
          </div>
          )}
          <div className="product-info-column">

          {isMobile &&(
          <div className="product-image-columnPVM">
            {/* Display a picture here */}
            <img src={asset?.talentCard.profile_new_url} alt="Profile" className="profilePicturePVM" />
          </div>
          )}

            
            <span className='crypto-index'>{asset?.talentCard.talent_name_original}</span>
          
            <div className='blockquote-target'>
            {asset?.talentCard.talent_first_name}&nbsp;{asset?.talentCard.talent_last_name}
            <span style={{ fontSize: '70%', fontWeight: 200, opacity: 0.8 }}>
            &nbsp;&nbsp;•&nbsp;&nbsp;{asset?.talentCard.talent_age}{t('yo')}
            </span>
            <span style={{ fontSize: '70%', fontWeight: 200, opacity: 0.8 }}>
            &nbsp;&nbsp;•&nbsp;&nbsp;{t(mainNationality)}
            </span>
            </div>
            <div className="ps-3rdline">
              <span className="classTalentTags" id="talentTags" dangerouslySetInnerHTML={{ __html: `${main_activity_html}${main_category_html}` }}></span>
              <span className="classTalentTags" id="infoClub" dangerouslySetInnerHTML={{ __html: `${nationClub_html}${leagueClub_html}${nameClub_html}` }}></span>
            </div>
            <div className="ps-3rdline">
              <span className="classTalentTags" id="talentPrizes" dangerouslySetInnerHTML={{ __html: `${tags_html}${prizes_html}` }}></span>
            </div>
            
            <div className="ps-4thline">
      <span className="force">
        <i style={{ fontSize: '70%', position: 'relative', top: '-1px' }} className="fa-solid fa-shield-halved"></i>
        &nbsp;{asset?.talentCard.talent_force}&nbsp;&nbsp;
        <span style={{ fontWeight: 300, fontSize: '110%' }}>{t('force')}</span>
      </span>
      <span className="XP">
        <i style={{ fontSize: '70%', position: 'relative', top: '-1px' }} className="fa-solid fa-bolt"></i>
        &nbsp;{asset?.talentCard.talent_experience}&nbsp;&nbsp;
        <span style={{ fontWeight: 300, fontSize: '110%' }}>{t('experience')}</span>
      </span>
      <span className="insta_prof">
        <i style={{ fontSize: '70%', position: 'relative', top: '-1px' }} className="fa-brands fa-instagram"></i>
        &nbsp;{followersTotal}
      </span>
      <span className="managers-count">
        <i style={{ fontSize: '70%', position: 'relative', top: '-1px' }} className="fa-solid fa-chess-rook"></i>
        &nbsp;
        <span style={{ fontWeight: 300, fontSize: '100%' }} id="managersCount"></span>
      </span>
    </div>

  {asset?.talentCard.talent_description && (
  <div className="modal-div">
    <h2>{t('about')} {asset.talentCard.talent_first_name}</h2>
    <div className="info-tab">
        <Suspense fallback={<div>{t('loading')}</div>}>
        <TalentDescriptionLazy description={formattedDescription} truncated />
        </Suspense>
    </div>

    <div className="info-tab">
    
    {asset?.talentCard.talent_instagram && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.instagram.com/${asset.talentCard.talent_instagram}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-instagram"></i> {asset.talentCard.talent_instagram}
    </a>
  </span>
)}

{asset?.talentCard.talent_youTube && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.youtube.com/${asset.talentCard.talent_youTube}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-youtube"></i> {asset.talentCard.talent_youTube}
    </a>
  </span>
)}



{asset?.talentCard.talent_tiktok && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.tiktok.com/@${asset.talentCard.talent_tiktok}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-tiktok"></i> {asset.talentCard.talent_tiktok}
    </a>
  </span>
)}

{asset?.talentCard.talent_facebook && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.facebook.com/${asset.talentCard.talent_facebook}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-facebook"></i> {asset.talentCard.talent_facebook}
    </a>
  </span>
)}

{asset?.talentCard.talent_twitch && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://m.twitch.tv/${asset.talentCard.talent_twitch}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-twitch"></i> {asset.talentCard.talent_twitch}
    </a>
  </span>
)}



{asset?.talentCard.talent_linkedIn && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://fr.linkedin.com/in/${asset.talentCard.talent_linkedIn}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-linkedin"></i> {asset.talentCard.talent_linkedIn}
    </a>
  </span>
)}

{asset?.talentCard.talent_pinterest && (
  <span
    className="classTalentTags"
    style={{
      background: '#171415',
      padding: '6px 20px',
      borderRadius: '5px',
      fontWeight: 100,
      fontSize: '90%',
      marginRight: '10px',
    }}
  >
    <a
      href={`https://www.pinterest.com/${asset.talentCard.talent_pinterest}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa-brands fa-pinterest"></i> {asset.talentCard.talent_pinterest}
    </a>
  </span>
)}



    </div>





  </div>
)}

{asset?.talentCard.talent_name_original && (
        <Suspense fallback={<div>{t('loading')}</div>}>
        <NewsStatusLazy
          talentUppercase={asset.talentCard.talent_name_original.toUpperCase()}
          onNewsCountChange={handleNewsCount} // This will update the newsNumber state
        />
      </Suspense>
      )}



{(newsNumber > 0 && asset?.talentCard.talent_name_original) && (
<div className="modal-div">
<h2>{t('LastNews')}</h2>
<div className="info-tab-news-profile">
<LastNewsDisplayLazy 
limit={5}
talentUppercase={asset?.talentCard.talent_name_original.toUpperCase()}
modal='profileViewModal'
onNewsCountChange={handleNewsCount}
/>
</div>
</div>
)}

<div className="modal-div">
    <h2 style={{ marginBottom: '-40px' }}>{t('weeklyPerformances')}</h2>
    <div className="info-tab">
{asset?.name && ( 
  <Suspense fallback={<div>{t('loading')}</div>}>
  <TalentPerformanceChartLazy 
talentName={asset.name} 
onFollowersUpdate={handleFollowersUpdate}
onBarClick={handleBarClick} // Pass the bar click handler
/>
</Suspense> )}
</div>

{/* Modal for selected bar data */}
{isModalTalentScoreOpen && selectedBar && (
  <Suspense fallback={<div>{t('loading')}</div>}>
          <ModalTalentScoreLazy
            isOpen={isModalTalentScoreOpen}
            onClose={handleCloseModal}
            barData={selectedBar}
            asset={asset} // Pass the asset
          />
          </Suspense>
        )}

<div className="modal-div">
    <h2>{t('averageValueOfCards')}</h2>

{isMobile === false && (
<div className="info-tab" style={{ width: '90%' }}>
{asset?.name && ( <Suspense fallback={<div>{t('loading')}</div>}>
  <ChartComponentLazy talentName={asset.name} />
</Suspense> )}
</div>
)}

{isMobile === true && (
<div className="info-tab" style={{ width: '100%' }}>
{asset?.name && ( 
  <Suspense fallback={<div>{t('loading')}</div>}>
  <ChartComponentLazy talentName={asset.name} />
</Suspense> )}
</div>
)}

</div>

<div className="modal-div">
              <h2>{t('lastSales')}</h2>
               
                <Suspense fallback={<div>{t('loading')}</div>}>
                <LastSalesLazy 
                talentName={asset?.talentCard.talent_name_original || ''}
                url_picture={asset?.talentCard.token_new_url|| ''}
                 />
                </Suspense>
              </div>





</div>
  
          </div>
        </div>
      </div>

{isModalTalentScoreOpen===false &&(
      <div className="stickyMenuPVMBottom">
        <div className="profile-info-buttons">
          <div className="sell-button-bottom" onClick={handleSecondButtonClick}>
            {t('myCards')} <span id="seeMyCardsBottom"></span>
          </div>
          <div className="buy-button-bottom" onClick={handleFirstButtonClick}>
            {t('buyCard')} <span id="buyMyCardsBottom"></span>
          </div>
        </div>
      </div>
)}
    
    </div>
  );
};

export default PageViewModal;
